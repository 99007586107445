/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Futura Now Headline Regular by Monotype
 * URL: https://www.myfonts.com/products/headline-regular-futura-now-348184
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Futura Now Headline Light by Monotype
 * URL: https://www.myfonts.com/products/headline-light-futura-now-348172
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Tabac G1 by Suitcase Type Foundry
 * URL: https://www.myfonts.com/products/g1-tabac-408864
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Futura Now Headline Medium by Monotype
 * URL: https://www.myfonts.com/products/headline-medium-futura-now-348177
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Futura Now Headline ExtraLight by Monotype
 * URL: https://www.myfonts.com/products/headline-extralight-futura-now-348162
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2023 MyFonts Inc. */

@font-face {
  font-family: "FuturaNowHeadlineXLt";
  src: url("../../assets/fonts/FuturaNowHeadlineExtraLight/font.woff2")
      format("woff2"),
    url("../../assets/fonts/FuturaNowHeadlineExtraLight/font.woff")
      format("woff");
}

@font-face {
  font-family: "Tabac-G1Regular";
  src: url("../../assets/fonts/TabacG1/font.woff2") format("woff2"),
    url("../../assets/fonts/TabacG1/font.woff") format("woff");
}

@font-face {
  font-family: "FuturaNowHeadlineRg";
  src: url("../../assets/fonts/FuturaNowHeadlineRegular/font.woff2")
      format("woff2"),
    url("../../assets/fonts/FuturaNowHeadlineRegular/font.woff") format("woff");
}

@font-face {
  font-family: "FuturaNowHeadlineMd";
  src: url("../../assets/fonts/FuturaNowHeadlineMedium/font.woff2")
      format("woff2"),
    url("../../assets/fonts/FuturaNowHeadlineMedium/font.woff") format("woff");
}

@font-face {
  font-family: "FuturaNowHeadlineLt";
  src: url("../../assets/fonts/FuturaNowHeadlineLight/font.woff2")
      format("woff2"),
    url("../../assets/fonts/FuturaNowHeadlineLight/font.woff") format("woff");
}
