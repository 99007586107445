@use '../abstracts/mixins' as *;

.three-columns {
    display: grid;
    gap: 1.5em;

    @include devices(mobile) {
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(3, 1fr);
    }
}
