@use '../abstracts/mixins' as *;

.legende {
    counter-reset: section;

    &__container {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        column-gap: calc(var(--padding) / 2);

        @include devices(tablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include devices(desktop) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        .item {
            display: flex;
            border-top: 1px solid var(--primary);

            p {
                font-family: var(--ff-medium);
                span {
                    font-family: var(--ff-light);
                }
            }
        }
        .item::before {
            display: flex;
            align-items: center;
            min-width: 75px;
            counter-increment: section;
            content: counter(section);
            font-family: var(--ff-tabac);
            font-size: clamp(1.75rem, 1.3426rem + 2.037vw, 3.125rem);
        }
    }
}
