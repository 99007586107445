// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

@use "../abstracts/" as *;

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */

body {
  background-color: white;
}

.route-container {
  position: absolute;
  width: 100%;
  z-index: -1;
  will-change: transform, opacity;
}

.site-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  will-change: opacity;
}

.offset {
  margin-top: 100px;
}

// Container
.container {
  width: 100%;
  max-width: var(--max-width);
  padding: var(--padding);

  &.center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.fluid {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 0;
}

.group {
  img {
    object-fit: contain;
    height: auto;
  }
}

img,
video {
  width: 100%;
  max-width: 100%;
}

.visually-hidden {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(
    1px 1px 1px 1px
  ); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(
    1px,
    1px,
    1px,
    1px
  ); /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(
    50%
  ); /*modern browsers, clip-path works inwards from each corner*/
  white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
}

.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include min-vh(100);
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.33) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: -1;
  }

  &__image {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    transition: opacity 1s ease-in-out;
  }

  .svg-heading {
    margin-bottom: 0;
  }
}
