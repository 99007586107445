// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

@use '../abstracts/mixins' as *;

.site-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;

    &__wrapper {
        display: flex;
        flex-direction: column-reverse;
        gap: 2em;

        @include devices(tablet) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__logos {
        display: flex;
        column-gap: 1.5em;
        justify-content: center;
        max-width: 100%;

        a {
            display: flex;
            align-items: flex-start;
            padding-top: 0.25em;
        }

        @include devices(mobile) {
            column-gap: 3em;
        }

        img {
            flex: 1;
            width: 175px;
        }
    }

    &__navigation {
        ul {
            display: flex;
            flex-wrap: wrap;
            gap: var(--padding);
            list-style: none;
            padding: 0;
            margin: 0;
            justify-content: center;

            @include devices(tablet) {
                justify-content: flex-start;
            }

            li {
                a {
                    position: relative;
                    color: var(--primary);
                    text-decoration: none;
                    text-transform: uppercase;
                    @include letter-spacing(100);

                    &::before {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 1px;
                        bottom: 0;
                        left: 0;
                        background-color: #000;
                        transform: scaleX(0);
                        transform-origin: left;
                        transition: transform 0.3s ease;
                    }

                    &:hover::before {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
}
