// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

@use '../abstracts/mixins' as *;

.site-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    isolation: isolate;
    color: var(--primary);
    background-color: #fff;
    // mix-blend-mode: difference;

    &__head {
        width: 100%;
        position: relative;
        display: flex;
        column-gap: 2em;
        justify-content: space-between;
        padding: 0.75em var(--padding);
        z-index: 9999;

        @include devices(tablet) {
            padding: 1em var(--padding);
        }

        @include devices(desktop) {
            padding: 1.25em var(--padding);
        }
    }

    &__logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            img {
                width: 200px;
            }
        }
    }

    &__burger {
        display: block;
        cursor: pointer;

        @include devices('desktop') {
            display: none;
        }

        .burger-line {
            width: 30px;
            height: 1px;
            background-color: #000;
            margin: 7px 0;
            transition:
                opacity 0.33s ease-in-out,
                transform 0.33s ease-in-out;
        }

        &--active {
            .burger-line:nth-child(1) {
                transform: translateY(0.5rem) rotate(45deg);
            }
            .burger-line:nth-child(2) {
                opacity: 0;
                transform: translateX(0.5rem);
            }
            .burger-line:nth-child(3) {
                transform: translateY(-0.5rem) rotate(-45deg);
            }
        }
    }

    &__main-nav {
        display: none;
        align-items: center;

        @include devices('desktop') {
            display: flex;
        }

        ul {
            display: flex;
            column-gap: 2em;
            padding-left: 0;
            list-style: none;
            margin: 0;

            li {
                a {
                    position: relative;
                    text-decoration: none;
                    color: inherit;
                    text-transform: uppercase;
                    @include letter-spacing(150);

                    &::before {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 1px;
                        bottom: 0;
                        left: 0;
                        background-color: #000;
                        transform: scaleX(0);
                        transform-origin: left;
                        transition: transform 0.3s ease;
                    }

                    &:hover::before {
                        transform: scaleX(1);
                    }

                    &.active::before {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }

    // Burger Menu Navigation
    &__nav {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        opacity: 0;
        pointer-events: none;
        clip-path: polygon(-50% 0, 0% 50%, -50% 100%, 0 100%, 0 0);
        z-index: 1;
        inset: 0;
        transition:
            clip-path 375ms cubic-bezier(0.36, 0, 0.45, 0.99),
            opacity 175ms cubic-bezier(0.36, 0, 0.45, 0.99);

        &--active {
            clip-path: polygon(100% 0, 150% 50%, 100% 100%, 0 100%, 0 0);
            opacity: 1;
            pointer-events: all;
        }

        @include devices('desktop') {
            display: none;

            &--active {
                clip-path: polygon(-50% 0, 0% 50%, -50% 100%, 0 100%, 0 0);
            }
        }

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, #ffffff 0%, #ffffff00 100%);

            ul {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 0;
                list-style: none;

                li {
                    margin: 0.5em 0;

                    .navlink {
                        margin: 0;

                        a {
                            font-size: clamp(1.8125rem, 1.7813rem + 0.1563vw, 1.9375rem);
                            line-height: 1.25;
                            text-decoration: none;
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}
