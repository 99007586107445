@use "../abstracts/mixins" as *;

.floorplan-viewer {
  &__container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: var(--padding);
    padding-top: calc(var(--padding) / 2);
  }

  &__floors {
    grid-column: 1 / 9;
    align-self: flex-end;

    @include devices(mobile) {
      grid-column: 1 / 7;
    }
  }

  &__stack {
    grid-column: 5 / 9;
    align-self: flex-start;

    @include devices(mobile) {
      grid-column: 7 / 9;
    }
  }

  &__controls {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1em;
    max-width: 66rem;

    button {
      background: none;
      font-family: var(--ff-tabac);
      font-size: clamp(1rem, 0.7407rem + 1.2963vw, 1.875rem);
      border: transparent;
      cursor: pointer;
      text-align: left;
      align-self: start;
      color: var(--darkgray);

      &.active {
        color: var(--primary);
      }

      &:hover {
        color: var(--primary);
      }
    }
  }
}
