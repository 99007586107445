// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

@use 'mixins' as *;

:root {
  --max-width: 1600px;
  --padding: 1em;

  @include devices(tablet) {
    --padding: 2em;
  }

  @include devices(desktop) {
    --padding: 4em;
  }

  --ff-regular: 'FuturaNowHeadlineRg', sans-serif;
  --ff-light: 'FuturaNowHeadlineLt', sans-serif;
  --ff-medium: 'FuturaNowHeadlineMd', sans-serif;
  --ff-extra-light: 'FuturaNowHeadlineXLt', sans-serif;
  --ff-tabac: 'Tabac-G1Regular', serif;

  --primary: #000;
  --secondary: #fff;
  --darkgray: #adadad;
  --gray: #f4f4f4;
}
