// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.button {
    font-family: var(--ff-tabac);
    font-size: clamp(1.8125rem, 1.7813rem + 0.1563vw, 1.9375rem);
    margin: 2em 0;

    a {
        color: inherit;
        text-decoration: none;
    }

    &__arrow {
        display: inline-block;
        width: 2.25em;
        margin-left: 0.5em;
        vertical-align: text-bottom;
    }
}
