@use '../abstracts/mixins' as *;

.collage {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    position: relative;

    .group {
        position: relative;
    }

    .animated-pattern {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        z-index: -1;
        transform: translate(5em, -5em);
        object-fit: cover;
    }

    &--default {
        .group:nth-child(1) {
            grid-column: 1 / 8;
        }

        .group:nth-child(2) {
            grid-column: 1 / 5;
            grid-row: 2;
            margin-top: -50%;
            z-index: -1;
        }

        .group:nth-child(3) {
            grid-column: 3 / 9;
            grid-row: 2;
            margin-top: -15%;
        }

        @include devices(mobile) {
            .group:nth-child(1) {
                grid-column: 1 / 6;
            }

            .group:nth-child(2) {
                grid-column: 3/7;
                grid-row: 2;
                margin-top: -60%;
                z-index: -1;
            }

            .group:nth-child(3) {
                grid-column: 5 / 9;
                grid-row: 2;
                margin-top: -30%;
            }
        }
    }

    &--single {
        .group:nth-child(1) {
            grid-column: 1 / 8;
        }

        .group:nth-child(2) {
            grid-column: 5/9;
            grid-row: 2;
            margin-top: -50%;
            z-index: -1;
        }

        @include devices(mobile) {
            .group:nth-child(1) {
                grid-column: 3/7;
            }

            .group:nth-child(2) {
                grid-column: 5/8;
                grid-row: 2;
                margin-top: -60%;
                z-index: -1;
            }
        }
    }

    &--fancy {
        gap: var(--padding);
        .animated-pattern {
            position: absolute;
            top: 0;
            left: 0;
            width: 60%;
            z-index: -1;
            transform: translate(-12.5em, 2.5em);
            object-fit: cover;
        }

        .group:nth-child(1) {
            grid-column: 1 / 9;

            @include devices(mobile) {
                grid-column: 1 / 7;
            }

            @include devices(tablet) {
                grid-column: 1 / 5;
                margin-top: 27.5%;
            }
        }

        .group:nth-child(2) {
            grid-column: 1 / 9;

            @include devices(tablet) {
                grid-column: 5 / 9;
            }
        }

        .group:nth-child(3) {
            grid-column: 2/9;
            grid-row: 3;
            margin-top: 0%;

            @include devices(desktop) {
                grid-column: 3/8;
                grid-row: 3;
                margin-top: -27.5%;
            }
        }
    }
}

.large-collage {
    display: grid;
    grid-template-columns: var(--padding) repeat(8, 1fr) var(--padding);
    padding: var(--padding) 0;
    row-gap: calc(var(--padding) / 2);

    .medium {
        font-size: clamp(1.5625rem, 0.9144rem + 3.2407vw, 3.75rem);
    }

    .large {
        font-size: clamp(1.875rem, 0.9491rem + 4.6296vw, 5rem);
    }

    .group {
        display: flex;
        flex-direction: column;
    }

    p {
        font-family: var(--ff-regular);
        font-size: clamp(1.25rem, 0.8796rem + 1.8519vw, 2.5rem);
        text-transform: uppercase;
    }

    .group:nth-child(1) {
        grid-column: 5 / 10;

        @include devices(mobile) {
            grid-column: 3 / 6;
        }
    }

    .group:nth-child(2) {
        grid-column: 2 / 7;

        .medium {
            @include devices(mobile) {
                margin-left: -1em;
            }
        }

        @include devices(mobile) {
            grid-column: 7 / 10;
        }
    }

    .group:nth-child(3) {
        grid-column: 3 / 10;

        @include devices(mobile) {
            margin-top: 2em;
        }
    }

    .group:nth-child(4) {
        grid-column: 2 / 10;
        text-align: end;
        line-height: 1.1;

        @include devices(mobile) {
            margin-top: 2em;

            p {
                margin-bottom: 0.25em;
            }
        }
    }

    .group:nth-child(5) {
        grid-column: 1 / 10;

        @include devices(mobile) {
            grid-column: 1 / 9;
        }
    }

    .group:nth-child(6) {
        grid-column: 3 / 10;
        @include devices(mobile) {
            margin-top: 5em;
        }
    }

    .group:nth-child(7) {
        grid-column: 2 / 9;

        @include devices(mobile) {
            p {
                align-self: flex-end;
            }
        }
    }

    .group:nth-child(8) {
        grid-column: 4 / 10;
        align-self: flex-start;

        @include devices(mobile) {
            grid-column: 2 / 6;

            img {
                max-width: 80%;
            }

            p {
                align-self: flex-end;
                margin-right: 1.5em;
            }
        }
    }

    .group:nth-child(9) {
        grid-column: 2 / 8;

        @include devices(mobile) {
            grid-column: 6 / 9;
        }
    }
}
