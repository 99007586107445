.quote {
    position: relative;
    &__background {
        position: absolute;
        inset: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .container {
        padding: calc(4 * var(--padding)) var(--padding);
    }
}
