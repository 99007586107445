// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

@use '../abstracts/mixins' as *;

.home-page {
    .hero {
        position: relative;
        z-index: -1;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        &__video {
            object-fit: cover;
            @include min-vh(100);
        }

        &__overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            inset: 0;

            div {
                position: relative;
                display: grid;
                place-items: center;
                width: 100%;
                height: 100%;

                img {
                    position: absolute;
                    max-width: 75%;
                }
            }
        }
    }

    .introduction {
        position: relative;
        background-color: white;

        .container {
            z-index: 2;
        }

        .background {
            z-index: 1;
        }
    }
}
