@use '../abstracts/mixins' as *;

.media-text {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: var(--padding);

    .group {
        position: relative;
        margin-bottom: var(--padding);
    }

    .animated-pattern {
        position: absolute;
        width: 100%;
        max-width: 700px;
        z-index: -1;
        object-fit: cover;
    }

    .group:nth-child(1) {
        flex-basis: 400px; // minimum width
        flex-grow: 1; // grow to fill available space
        padding-bottom: var(--padding);

        .animated-pattern {
            bottom: 0;
            left: 0;
            transform: translate(-5em, 4em);
        }

        @include devices(mobile) {
            padding-bottom: 0;
        }
    }

    .group:nth-child(2) {
        flex-basis: 600px; // minimum width
        flex-grow: 9999; // grow to fill available space

        .animated-pattern {
            bottom: 0;
            right: 0;
            transform: translate(5em, 4em);
        }
    }

    &.no-padding-right {
        padding-right: 0;
        .group:nth-child(1) {
            padding-right: var(--padding);

            @include devices(desktop) {
                padding-right: 0;
            }
        }
    }

    &.no-padding-left {
        padding-left: 0;
        .group:nth-child(2) {
            padding-left: var(--padding);

            @include devices(desktop) {
                padding-left: 0;
            }
        }
    }
}
