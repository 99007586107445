/**
 * Basic typography style for copy text
 */

@use '../abstracts/mixins' as *;

body {
  font-size: clamp(1.125rem, 1.0781rem + 0.2344vw, 1.3125rem);
  font-family: var(--ff-light);
  line-height: 1.5;
  color: var(--primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;

  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;

  @include devices('mobile') {
    hyphens: none;
    -moz-hyphens: none;
    -webkit-hyphens: none;
    -ms-hyphens: none;
  }
}

p {
  font-weight: normal;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

p {
  text-wrap: balance;

  @include devices('mobile') {
    hyphens: none;
    -moz-hyphens: none;
    -webkit-hyphens: none;
    -ms-hyphens: none;
  }
}

.is-font-family-light {
  font-family: var(--ff-light);
}

.is-font-family-regular {
  font-family: var(--ff-regular);
}

.is-font-family-medium {
  font-family: var(--ff-medium);
}

.is-font-family-extra-light {
  font-family: var(--ff-extra-light);
}

.is-font-family-tabac {
  font-family: var(--ff-tabac);
  font-weight: normal;
}

.is-font-size-xl {
  font-size: clamp(5rem, 4.6875rem + 1.5625vw, 6.25rem);
}

.is-font-size-l {
  font-size: clamp(3.125rem, 2.9688rem + 0.7813vw, 3.75rem);
}

.is-font-size-xs {
  font-size: 17px;
}

.is-font-size-m {
  font-size: clamp(1.75rem, 1.3426rem + 2.037vw, 3.125rem);
  line-height: 1.25;
}

.is-font-size-28 {
  font-size: clamp(1.5rem, 1.4259rem + 0.3704vw, 1.75rem);
}

.centered {
  text-align: center;
}

.right {
  text-align: right;
}

strong {
  font-family: var(--ff-regular);
}

.line-height-1 {
  line-height: 1.5;
}

.uppercase {
  text-transform: uppercase;
}
