@use '../abstracts' as *;

.contact {
    &__container {
        display: flex;
        flex-wrap: wrap;
        // align-items: start;
        gap: var(--padding);
        padding-bottom: 0;
    }

    &__intro-paragraph {
        max-width: 35ch;
        margin: var(--padding) 0;
    }

    &__telefon {
        font-family: var(--ff-tabac);
        font-size: clamp(2.1875rem, 1.7969rem + 1.9531vw, 3.75rem);
        margin-bottom: 0.25em;
    }

    &__button {
        margin-top: 1em !important;
    }

    .group:nth-child(1) {
        flex-basis: 600px; // minimum width
        flex-grow: 1; // grow to fill available space
        padding-bottom: var(--padding);
    }

    .group:nth-child(2) {
        display: flex;
        align-items: flex-end;
        flex-basis: 300px; // minimum width
        flex-grow: 1; // grow to fill available space

        img {
            // min-width: 300px;
            max-width: 600px;
        }
    }
}
