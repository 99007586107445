@use '../abstracts/mixins' as *;

.crosslinks {
    .container {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        padding: var(--padding);
        gap: calc(var(--padding) / 2);
        align-items: start;

        @include devices(tablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        h3 {
            margin: 0.1em 0;
        }

        .button {
            margin: 0.75em 0;
        }
    }
}
