@use "../abstracts/mixins" as *;

.data {
  &__container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: calc(var(--padding) / 2);

    @include devices(desktop) {
      grid-template-columns: repeat(3, minmax(350px, 1fr));
    }

    .item {
      .key {
        font-family: var(--ff-tabac);
        margin: 0;
        margin-bottom: 0.25em;
        text-transform: uppercase;
      }

      .value {
        font-family: var(--ff-tabac);
        margin: 0;
        border-top: 1px solid black;
        white-space: nowrap;
        @include letter-spacing(0);

        span {
          @include letter-spacing(-40);
        }
      }
    }
  }
}
